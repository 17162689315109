import axios from "axios";
import { API_END_POINTS, REACT_APP_BASE_URL } from "../constants/Constants";

export class AuthService {
  getUserInfo(email) {
    return axios.get(`${REACT_APP_BASE_URL}${API_END_POINTS.GET_USER_DETAIL}/${email}`);
  }

  registerUser = (email, appVerifyUrl) => {
    const body = {
      username: "",
      email,
      appVerifyUrl: appVerifyUrl,
    };
    return axios.post(`${REACT_APP_BASE_URL}${API_END_POINTS.REGISTER_USER}`, body);
  };

  verifyUser = async (token) =>
    axios.post(`${REACT_APP_BASE_URL}${API_END_POINTS.VERIFY_USER}`, null, {
      params: { token },
    });
}
